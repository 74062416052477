<template>
  <div class="bodys">
    <div class="home">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
        <el-breadcrumb-item class="dangq">当前位置：</el-breadcrumb-item>
        <el-breadcrumb-item class="dangq"
          ><router-link to="/">汇成国际</router-link></el-breadcrumb-item
        >
        <el-breadcrumb-item class="dangq"
          ><span style="user-select: auto">{{
            details.title
          }}</span></el-breadcrumb-item
        >
      </el-breadcrumb>
      <div class="cont">
        <div class="cont_left">
          <div class="left_top2">
            <div class="top_name">
              <div class="name_left" style="align-item: center">
                <div class="left_1">垫本预付类产品</div>
                <div class="left_2" v-if="details.status == 2">操作中</div>
                <div class="left_2" v-else-if="details.status == 3">已清盘</div>
              </div>
              <div class="name_right" v-if="details.status == '2'">
                距到期时间:
                <van-count-down
                  :time="details.end_at * 1000 - date"
                  format="DD天HH小时mm分钟"
                />
              </div>
              <div class="name_right" v-else-if="details.status == '3'">
                到期时间:{{
                  (details.end_at * 1000) | format("YYYY-MM-DD HH:mm")
                }}
              </div>
            </div>
            <div class="hengx"></div>
            <div class="center_name">
              <div class="center_left">
                <div class="yuqi" v-if="details.status == 2" style="width:35%">
                  当前定制收益率(年):
                  <span class="reds"
                    >{{ details.annual_profit / 100 }}%+{{
                      details.float_profit / 100
                    }}%</span
                  >
                </div>
                <div class="yuqi" v-else-if="details.status == 3" style="width:35%">
                  到期定制收益率(年):
                  <span class="reds"
                    >{{ details.annual_profit / 100 }}%+{{
                      details.float_profit / 100
                    }}%</span
                  >
                </div>
                <div class="yuqi" v-if="details.status == 2">
                  定制金额: <span>{{ details.amount / 100 }}元</span>
                </div>
                <div class="yuqi" v-else-if="details.status == 3">
                  定制总金额: <span>{{ details.amount / 100 }}元</span>
                </div>

                <div class="yuqi yuqis" v-if="details.status == 2">
                  操作周期: <span>{{ details.days }}天</span>
                </div>
                <div class="yuqi yuqis" v-else-if="details.status == 3">
                  定制周期: <span>{{ details.days }}天</span>
                </div>
                <div class="yuqi yuqis" style="width:35%">
                  定制收益:
                  <span
                    >{{ (details.final_amount - details.amount) / 100 }}元</span
                  >
                </div>
                <div class="yuqi yuqis" v-if="details.status == 2">
                  当前净值: <span>{{ details.final_amount / 100 }}元</span>
                </div>
                <div class="yuqi yuqis" v-else-if="details.status == 3">
                  到期净值: <span>{{ details.final_amount / 100 }}元</span>
                </div>
              </div>
            </div>
          </div>
          <div class="cont_center">
            <div :class="tab == true ? 'tabj2 tabj' : 'tabj'" @click="tabjs">
              定制信息
              <div class="lanh" v-show="lanh"></div>
            </div>
            <div :class="tab2 == true ? 'tabj2 tabj' : 'tabj'" @click="tabjs2">
              定制记录
              <div class="lanh" v-show="lanh2"></div>
            </div>
            <div
              :class="tab3 == true ? 'tabj2 tabj' : 'tabj'"
              @click="tabjs3"
              v-if="details.status == 3"
            >
              交易记录
              <div class="lanh" v-show="lanh3"></div>
            </div>
          </div>
          <div class="left_bottom">
            <div class="content" v-show="xiang == 1">
              <div class="information" @click.stop="information0">
                <div class="information_top">
                  <img
                    :src="zhan0 == false ? zhankai : zhankai2"
                    class="zhankai"
                    alt=""
                  />
                  <div class="information_title">产品基本信息</div>
                </div>
                <div class="information_nei" v-show="zhan0">
                  <div class="ji_zi">
                    目标募集金额{{
                      details.target_amount / 100
                    }}元，产品最大风险{{
                      details.max_risk
                    }}%，产品操作期预期收益{{ details.exp_income }}%
                  </div>
                  <table class="table">
                    <tr>
                      <td class="td1">角色</td>
                      <td class="td2">承担风险</td>
                      <td class="td3">预期总收益(元)</td>
                    </tr>
                    <tr>
                      <td class="td1">合伙人</td>
                      <td class="td2">0%</td>
                      <td class="td3">
                        {{ details.annual / 100 }}+{{ details.float / 100 }}
                      </td>
                    </tr>
                    <tr>
                      <td class="td1">操盘手</td>
                      <td class="td2">{{ details.risk_fund_rate / 100 }}%</td>
                      <td class="td3">{{ details.operatorProfit / 100 }}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="information" @click.stop="information1">
                <div class="information_top">
                  <img
                    :src="zhan1 == false ? zhankai : zhankai2"
                    class="zhankai"
                    alt=""
                  />
                  <div class="information_title">盈亏分配</div>
                </div>
                <div class="information_nei" v-show="zhan1">
                  <table class="table">
                    <tr>
                      <td class="td4">角色</td>
                      <td class="td5">收益分配</td>
                    </tr>
                    <tr>
                      <td class="td6">合伙人</td>
                      <td class="td7">
                        <p>固定年化收益率{{ details.annual_profit / 100 }}%</p>
                        <p>浮动收益分配:从产品收益中分</p>
                        <p>成，固定分{{ details.float_profit / 100 }}%</p>
                      </td>
                    </tr>
                    <tr>
                      <td class="td4">操盘手</td>
                      <td class="td5">产品盈利分给合伙人后的剩余部分</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="information" @click.stop="information2">
                <div class="information_top">
                  <img
                    :src="zhan2 == false ? zhankai : zhankai2"
                    class="zhankai"
                    alt=""
                  />
                  <div class="information_title">产品其他信息</div>
                </div>
                <div
                  class="information_nei information_nei_bottom"
                  v-show="zhan2"
                >
                  <div class="other">
                    <div class="other_2">
                      <div class="other_3">
                        <div>定制金额:</div>
                        <div>¥{{ details.amount / 100 }}</div>
                      </div>
                      <div class="other_3">
                        <div>定制开始时间:</div>
                        <div>
                          {{ (details.begin_at * 1000) | format("YYYY-MM-DD") }}
                        </div>
                      </div>
                      <div class="other_3">
                        <div>产品结束时间:</div>
                        <div>
                          {{ (details.end_at * 1000) | format("YYYY-MM-DD") }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="information" @click.stop="information3">
                <div class="information_top">
                  <img
                    :src="zhan3 == false ? zhankai : zhankai2"
                    class="zhankai"
                    alt=""
                  />
                  <div class="information_title">交易风格</div>
                </div>
                <div
                  class="information_nei information_nei_bottom"
                  v-show="zhan3"
                >
                  <div class="trading-style">
                    <div class="trading-style-item">
                      <div class="trading-item">
                        交易平台：<span>{{ details.trading_platform }}</span>
                      </div>
                      <div class="trading-item">
                        买卖方向：<span>{{ details.trading_direction }}</span>
                      </div>
                    </div>
                    <div class="trading-style-item">
                      <div class="trading-item">
                        投资策略：<span>{{ details.investment }}</span>
                      </div>
                      <div class="trading-item">
                        投资范围：<span>{{ details.invest_scope }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="content2" v-show="xiang == 2">
              <div class="content2_cont">
                <!-- 列表标题 -->
                <div class="list_title">
                  <div>序号</div>
                  <div>用户名</div>
                  <div>入伙金额(元)</div>
                  <div>入伙时间</div>
                  <div>备注</div>
                </div>
                <!-- 循环列表 -->
                <div class="list">
                  <div>1</div>
                  <div>{{ record1.name }}</div>
                  <div>{{ Math.abs(record1.amount / 100) }}</div>
                  <div>
                    {{ (record1.create_at * 1000) | format("YYYY-MM-DD") }}
                  </div>
                  <div>风险金</div>
                </div>
                <div class="list">
                  <div>2</div>
                  <div>{{ record2.name }}</div>
                  <div>{{ Math.abs(record2.amount / 100) }}</div>
                  <div>
                    {{ (record2.create_at * 1000) | format("YYYY-MM-DD") }}
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
            <!-- 交易记录 -->
            <div
              class="content2"
              v-show="xiang == 3"
              v-if="details.status == 3"
            >
              <div class="content2_cont">
                <div class="tables">
                  <!-- 表头
                <div class="table-header">
                  <table cellspacing="0" cellpadding="0" border="0">
                    <colgroup>
                      <col v-for="(item, index) in tableHead" :key="index" />
                    </colgroup>
                  </table>
                </div> -->
                  <div class="table-content">
                    <table cellspacing="0" cellpadding="0" border="0">
                      <colgroup>
                        <col v-for="(item, index) in tableHead" :key="index" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th
                            colspan="1"
                            rowspan="1"
                            v-for="(item, index) in tableHead"
                            :key="index"
                          >
                            {{ item }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in details.excel" :key="index">
                          <td
                            colspan="1"
                            rowspan="1"
                            v-for="(item2, index2) in item"
                            :key="index2"
                          >
                            {{ item2 }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cont_right">
          <div class="gao_nei">
            <div class="gao_title">
              <div class="title_zi">最新产品</div>
              <div
                class="alls"
                @click="$router.replace('/customized')"
                style="cursor: pointer"
              >
                全部<img
                  src="../../assets/img/next2.png"
                  style="width: 10px"
                  alt=""
                />
              </div>
            </div>
            <div class="hengx2"></div>
            <div class="gao_list_top">
              <div>产品名称</div>
              <div>预期年化</div>
            </div>
            <div
              class="gao_list"
              v-for="(item, index) in list"
              :key="index"
              @click="
                $router.push({
                  path: '/customwinding',
                  query: { id: item.id },
                })
              "
            >
              <div>{{ item.title }}</div>
              <div>
                {{ item.annual_profit / 100 }}%+{{ item.float_profit / 100 }}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BASE_URL } from "@constants/constants";
import {
  getBespokeDetail,
  bespokeDetail,
  bespokeDetails,
} from "@network/bespokeDetail";
export default {
  name: "Customwinding",
  data() {
    return {
      date: new Date(),
      net: BASE_URL,
      zhankai: require("../../assets/img/zhankai.png"),
      zhankai2: require("../../assets/img/zhankai2.png"),
      lanh: true, //tab 横杠
      lanh2: false, //tab 横杠
      lanh3: false, //tab 横杠
      tab: true, //tab 切换
      tab2: false, //tab 切换
      tab3: false, //tab 切换
      xiang: 1, //tab 内容
      zhan0: false,
      zhan1: false,
      zhan2: false,
      zhan3: false,
      page: 1,
      limit: 8,

      details: {}, //详情
      list: [], //最新

      record1: {}, //记录1
      record2: {}, //记录2
      tableHead: [
        '开仓时间',
        "交易品种",
        "交易类型",
        "交易量",
        "开仓价位",
        "平仓时间",
        "平仓价位",
        '手续费',
        '库存费',
        "交易盈亏",
      ],
    };
  },
  watch: {
    $route(n, o) {
      if (n.fullPath !== o.fullPath) {
        //监听路由参数是否变化
        this.bespokeDetails();
        this.getBespokeDetail();
      }
    },
  },
  created() {
    console.log(this.$route.query.id);
    this.bespokeDetails();
    this.getBespokeDetail();
  },
  components: {},

  computed: {
    today() {
      var day = new Date();
      day.setTime(day.getTime() + 24 * 60 * 60 * 1000 * 2);
      var s2 =
        day.getFullYear() +
        "" +
        this.format(day.getMonth() + 1) +
        "" +
        this.format(day.getDate());
      return s2;
    },
    mtoday() {
      var day = new Date();
      day.setTime(day.getTime() + 24 * 60 * 60 * 1000 * 3);
      var s3 =
        day.getFullYear() +
        "" +
        this.format(day.getMonth() + 1) +
        "" +
        this.format(day.getDate());
      return s3;
    },
  },
  methods: {
    format(num) {
      if (num < 10) return "0" + num;
      return num;
    },
    bespokeDetails() {
      console.log(this.$route.query.id);
      bespokeDetails(this.$route.query.id).then((res) => {
        console.log(res);
        if (res.code != 0) {
          if (res.code == 403) {
            this.$storage.remove("token");
            this.$message.error({
              message: "请重新登录",
              offset: 300,
            });
            this.$router.replace("/home");
            return false;
          }
          this.$message.error({
            message: res.msg,
            offset: 300,
          });
          return false;
        }
        this.details = res.data;
        this.record1 = res.data.risk_fund;
        this.record2 = res.data.fund;
      });
    },
    //获取定制 最新
    getBespokeDetail() {
      getBespokeDetail(
        "1",
        "8",
        this.today,
        this.mtoday,
        "50000",
        "2",
        "",
        "5",
        "asc"
      ).then((res) => {
        console.log(res);
        if (res.code != 0) {
          this.$message.error({
            message: "获取失败",
            offset: 300,
          });
          return false;
        }
        this.list = res.data;
      });
    },
    information0() {
      this.zhan0 = !this.zhan0;
    },
    information1() {
      this.zhan1 = !this.zhan1;
    },
    information2() {
      this.zhan2 = !this.zhan2;
    },
    information3() {
      this.zhan3 = !this.zhan3;
    },
    tabjs() {
      this.lanh = true;
      this.lanh2 = false;
      this.lanh3 = false;
      this.tab = true;
      this.tab2 = false;
      this.tab3 = false;
      this.xiang = 1;
    },
    tabjs2() {
      this.lanh = false;
      this.lanh3 = false;
      this.lanh2 = true;
      this.tab = false;
      this.tab3 = false;
      this.tab2 = true;
      this.xiang = 2;
    },
    tabjs3() {
      this.lanh = false;
      this.lanh2 = false;
      this.lanh3 = true;
      this.tab = false;
      this.tab2 = false;
      this.tab3 = true;
      this.xiang = 3;
    },
  },
};
</script>
<style lang='less' scoped>
@import "../../assets/css/customized/customwinding.less";
</style>
